import React from 'react'
import OrtYaml from '../data/orte.yaml'
import AusstellungYaml from '../data/ausstellung.yaml'
import KategorienYaml from '../data/akategorien.yaml'
import ZeitenYaml from '../data/zeiten.yaml'
import Layout from '../components/layout'

import MySEO from '../components/seo'
import SelectBox from '../components/SelectBox'
import Nichts from '../components/nichts'
import { Container,Flex} from 'theme-ui'

const Ausstellung = () => {
    
    const orte = OrtYaml.content
    const kategorien = KategorienYaml.content
    const thema= AusstellungYaml.content
    const zeiten= ZeitenYaml.content

    if(orte&&kategorien&&thema)
    return(
        <Layout>
             <MySEO title="Ausstellungen" description="Liste von aktuellen Ausstellungen im Oberen Mittelrheintal" />

        
             <Flex>
           
           <SelectBox kategorien={kategorien} orte={orte} zeiten={zeiten} thema={thema} titel="Ausstellungen" />
           </Flex>
        </Layout>
    )
    return (<Layout><Container><Nichts message="Zur Zeit haben wir keine Ausstellungen auf unserer Webseite registriert."/></Container></Layout>)

}
        
export default Ausstellung